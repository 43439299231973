/* eslint-disable @typescript-eslint/prefer-for-of */
const getMetaTag = (name: string) => {
  const metas = document.getElementsByTagName("meta");
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute("name") === name) {
      return metas[i];
    }
  }

  return null;
};

const getMetaContent = (name: string) => {
  const metaTag = getMetaTag(name);
  if (!metaTag) return null;

  return metaTag.getAttribute("content");
};

type Callback = (token: string) => void;

const gRecaptchaExecute = (
  cb: Callback,
  setRecaptchaError?: (isRecaptchaError: boolean) => void
) => {
  // SOURCE: https://developers.google.com/recaptcha/docs/loading
  // How this code snippet works:
  // This logic overwrites the default behavior of `grecaptcha.ready()` to
  // ensure that it can be safely called at any time. When `grecaptcha.ready()`
  // is called before reCAPTCHA is loaded, the callback function that is passed
  // by `grecaptcha.ready()` is enqueued for execution after reCAPTCHA is
  // loaded.

  if (typeof grecaptcha === "undefined") {
    window.grecaptcha = {
      ready: readyCb => {
        if (typeof grecaptcha === "undefined") {
          // window.__grecaptcha_cfg is a global variable that stores reCAPTCHA's
          // configuration. By default, any functions listed in its 'fns' property
          // are automatically executed when reCAPTCHA loads.
          const c = "___grecaptcha_cfg";
          window[c] = window[c] || {};
          (window[c].fns = window[c].fns || []).push(readyCb);
        } else {
          readyCb();
        }
      },
    } as any;
  }

  const recaptchaKey = getMetaContent("recaptchaKey");
  grecaptcha.ready(() => {
    if (typeof grecaptcha.execute === "undefined") {
      // As a measure of last resort, display an error for the user
      // to refresh the page
      if (setRecaptchaError) {
        setRecaptchaError(true);
      }
    } else {
      grecaptcha.execute(recaptchaKey, { action: "submit" }).then(cb);
    }
  });
};

export { gRecaptchaExecute };
