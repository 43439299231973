import { css } from "@emotion/react";

export const textLeft = css`
  text-align: left;
`;

export const form = css`
  width: 100%;
  ${textLeft}
`;

export const flex = css`
  display: flex;
`;

export const row = css`
  ${flex}
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const marginBottom = css`
  margin-bottom: 1rem;
`;

export const checkboxWrapper = css`
  margin: 1.5rem 0 0.5rem 0;
`;
