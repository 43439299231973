import type { ReactNode } from "react";

import { wrapper } from "./SignInWrapper.styles";

interface Props {
  children: ReactNode;
}

const SignInWrapper = ({ children, ...rest }: Props) => {
  return (
    <div css={wrapper} {...rest}>
      {children}
    </div>
  );
};

export { SignInWrapper };
