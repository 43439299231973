import { useTranslation } from "react-i18next";

import { ErrorBox } from "@unauthenticated/shared/components/ErrorBox/ErrorBox";
import { l } from "@unauthenticated/shared/locales/i18n";

const UnauthorizedErrorBox = () => {
  const { t } = useTranslation();

  return <ErrorBox error={t(l.signIn.NotAuthorized)} subMessage={t(l.signIn.ContactAPrac)} />;
};

export { UnauthorizedErrorBox };
