import { css } from "@emotion/react";

export const wrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  max-width: 31.5rem;
  margin: 0 auto;
`;
