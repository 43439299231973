import { useTranslation } from "react-i18next";

import { ErrorBox } from "@unauthenticated/shared/components/ErrorBox/ErrorBox";
import { l } from "@unauthenticated/shared/locales/i18n";

const RecaptchaErrorBox = () => {
  const { t } = useTranslation();

  return (
    <ErrorBox
      error={t(l.signIn.recaptchaErrorBox.title)}
      subMessage={t(l.signIn.recaptchaErrorBox.description)}
    />
  );
};

export { RecaptchaErrorBox };
