const EMAIL_AGENTS = [
  "gmail.com",
  "outlook.com",
  "icloud.com",
  "yahoo.com",
  "zoho.com",
  "hotmail.com",
  "protonmail.com",
  "aol.com",
  "gmx.com",
  "comcast.com",
  "msn.com",
  "live.com",
  "rogers.com",
  "yahoo.ca",
  "hotmail.ca",
  "shaw.ca",
  "sympatico.ca",
  "bell.net",
  "hey.com",
  "fullscript.com",
];
export { EMAIL_AGENTS };
