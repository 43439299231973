export const signIn = {
  signInAccount: "Sign in to your account",
  ForgotPassword: "Forgot password?",
  StaySignedIn: "Stay signed in",
  staySignedInFor7Days: "Stay signed in  for 7 days",
  SignIn: "Sign in",
  ContinueToFullscript: "Continue to Fullscript",
  NewToFullScript: "New to Fullscript?",
  Emerson: {
    NewToEmersonEcologics: "New to Emerson Ecologics?",
    ResetPassword: "Forgot your password?",
    ResetLinkText: "We'll email you a link to reset it and get back on track.",
  },
  CreateAccount: "Create an account",
  CaptchaFailed:
    "For security purposes we need to verify your account. Please check your email for an easy sign in link.",
  backToSignIn: "Back to sign in options",
  enterPasswordBelow: "Please enter your password below to sign in to your account.",
  PasswordIsRequired: "Password is required",
  IncorrectEmailOrPassword: "Incorrect email or password. Please try again.",
  ResetPassword: "Reset your password",
  NotAuthorized: "You are not authorized to sign in with this email",
  CaptchaError:
    "Captcha verification failed. If you have an account we'll send you an email with a magic link.",
  EmersonCaptchaError: "Captcha verification failed.",
  recaptchaErrorBox: {
    title: "Something went wrong",
    description: "Please reload the page and try again.",
  },
  ContactAPrac: "Please contact your practitioner",
  HappensToTheBest:
    "It happens to the best of us. We'll send you an email with instructions to reset your password.",
  EmailMeLink: "Email me a reset link",
  ReturnToSignIn: "Return to Sign In",
  SetYourPassword: "Set your new password",
  ItMustBeLong: "It must be at least 8 characters long.",
  PlaceholderPassword: "New password",
  PlaceHolderConfirmPassword: "Confirm new password",
  EmailHasBeenSent: "An email has been sent to {{ email }}",
  textMessageHasBeenSent:
    "A text message has been sent to the mobile number ending in {{ phoneLast4 }}",
  IfEmailExists:
    "If the account <1>{{ email }}</1> exists, you will receive an email with instructions on how to set a new password.",
  DidNotGetEmail: "Didn't get the email? Check your spam folder or <1>resend</1>.",
  didNotGetTextMessage: "Didn't get the text message? <1>Resend</1>.",
  EmailSentAgain: "Email succesfully sent again!",
  emailNotSent: "An error occurred while sending your email. Please try again.",
  textMessageNotSent: "An error occurred while sending your text message. Please try again.",
  PasswordsDontMatch: "The passwords you entered do not match.",
  LinkIsBroken: "Hmm, that link appears to be broken.",
  TryAgainResetPassword: "To reset your password, please try again.",
  GmailNotConnected: "Your Google email isn’t connected to a Fullscript account.",
  PleaseTryAnotherEmail: "Please try another email, or <1>sign up</1>.",
  signUpRedirectCTA: "sign up",
  twoFactorAuthForm: {
    backToSignIn: "Back to Sign in",
    codeWillExpire: "This code will expire in 10 minutes.",
    enterYourCode: "Enter your code",
    errorMessage: "An error occurred while authenticating your account. Please try again.",
    oneTimeCode: "One-time code",
    oneTimeCodeMismatch: "The code does not match. Try again.",
    otpRetrieval: {
      app: "Enter the one-time code from your authenticator app to sign in.",
      email: "A one-time code has been sent to your email <strong>{{email}}</strong> to sign in.",
      sms: "A one-time code has been sent to your mobile number ending in <strong>{{mobileNumber}}</strong>. Enter the code below to sign in.",
    },
    unableToAccessOtpMethod: {
      app: "Unable to access your authenticator app?",
      email: "Unable to access your email account?",
      sms: "Unable to access your text messages?",
    },
    useBackupCodeToSignIn: "Use backup code to sign in",
  },
  backupCode: "Backup code",
  backupCodeForm: {
    title: "Enter your backup code",
    subtitle: "Enter your backup code to sign in.",
    backupCodeIncorrect: "Backup code was incorrect. Try again.",
  },
  newBackupCodeView: {
    subtitle:
      "<0>Here is your new backup code. Please store it securely.</0> <1>You can view this again in your {{storePlatform}} <2><0>Account settings</0></2>.</1>",
  },
  contactFullscriptSupport: "Contact Fullscript support",
  contactEmersonSupport: "Contact Emerson Ecologics support",
  accountLocked: "Account locked",
  maximumAttemptsExceeded:
    "Maximum number of attempts exceeded. For security reasons, please try again in 10 minutes.",
} as const;
