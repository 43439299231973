const useIsWebview = () => {
  const userAgent = navigator.userAgent;
  const normalizedUserAgent = userAgent.toLowerCase();

  // We need to typecast as any here because standalone is a
  // nonstandard property and is ONLY accessible on iOS.
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator#non-standard_properties
  const standalone = (navigator as any).standalone;

  const isIos =
    /ip(ad|hone|od)/.test(normalizedUserAgent) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
  const isAndroid = /android/.test(normalizedUserAgent);
  const isSafari = /safari/.test(normalizedUserAgent);

  return (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari);
};

export { useIsWebview };
