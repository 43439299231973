import Fuse from "fuse.js";

import { EMAIL_AGENTS } from "./constants";

const options = {
  includeScore: true,
  threshold: 0.3,
};

const fuse = new Fuse(EMAIL_AGENTS, options);

const emailFuzzyMatch = (email: string) => {
  if (!email?.includes("@")) return;

  const [emailName, emailSuffix] = email.split("@");
  const fuseResults = fuse.search(emailSuffix);
  if (fuseResults.length === 0) return;

  const { item, score } = fuseResults[0];
  if (score === 0) return;

  return `${emailName}@${item}`;
};

export { emailFuzzyMatch };
