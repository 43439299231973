import { css, type Theme } from "@emotion/react";

import { dimensions } from "@styles";

export const noStyleButton = (theme: Theme) => css`
  color: ${theme.success.textBase};
  padding: 0;
  word-break: break-word;
  text-decoration: underline;

  @media (min-width: ${dimensions.tablet}) {
    cursor: pointer;
  }
`;
