const SvgFullscriptLogoGreenLeafPigeonText = props => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 309 51"
    style={{
      enableBackground: "new 0 0 309 51",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">{"\n\t.st0{fill:#88B04B;}\n\t.st1{fill:#404F5E;}\n"}</style>
    <title>{"Page 1"}</title>
    <desc>{"Created with Sketch."}</desc>
    <g id="_x30_6" transform="translate(-402.000000, -371.000000)">
      <g id="Page-1" transform="translate(402.000000, 371.000000)">
        <path
          id="Fill-3"
          className="st0"
          d="M45.7,17c0,0,0,0.1,0,0.1c0,0.6-0.9,14.2-17.9,20.3c0,0,0,0,0,0v-8.4l0,0l10.2-11 c0.9-1,0.9-2.5-0.1-3.5c-1-0.9-2.6-0.9-3.5,0.1l-6.6,7.1l0,0v-7.3c0,0,0,0,0-0.1c0.8-1.8,4.7-9.1,14.4-9.1c0.1,0,0.2,0,0.3,0 C46.3,9.9,45.7,16.9,45.7,17z M22.9,21.9L22.9,21.9l-6.6-7.1c-0.9-1-2.5-1.1-3.5-0.1c-1,0.9-1.1,2.5-0.1,3.5l10.2,11l0,0v8.4 c0,0,0,0,0,0C5.9,31.3,5,17.7,5,17.1c0,0,0-0.1,0-0.1c0-0.1-0.6-7.1,3.2-11.6c0.1,0,0.2,0,0.3,0c9.7,0,13.6,7.3,14.4,9.2 c0,0,0,0,0,0V21.9z M45.4,1.2c-0.4-0.4-1-0.7-1.6-0.7C34.5-0.1,28.5,4.6,25.3,9c-3.2-4.4-9.2-9-18.5-8.5c-0.6,0-1.2,0.3-1.6,0.7 c-6,5.9-5.3,15.3-5.2,16.2c0,0.7,0.4,5.3,3.5,10.5c2.9,5.1,8.9,11.8,21,15.2c0.1,0,0.1,0,0.2,0.1h0c0,0,0.1,0,0.1,0 c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0l0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c22.3-6.3,24.3-24,24.4-25.6 C50.6,16.9,51.5,7.3,45.4,1.2z"
        />
        <path
          id="Fill-8"
          className="st1"
          d="M91.5,2.5c0.4,0,0.7,0.1,1,0.4s0.4,0.6,0.4,1v3.8c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4 H74.3v10.9h16.1c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.8c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4H74.3V39 c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4h-4.4c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1V3.9c0-0.4,0.1-0.7,0.4-1 s0.6-0.4,1-0.4H91.5z"
        />
        <path
          id="Fill-10"
          className="st1"
          d="M97.9,14.1c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4h4.1c0.4,0,0.7,0.1,1,0.4 c0.3,0.3,0.4,0.6,0.4,1v13.6c0,2.1,0.5,3.8,1.4,5.1c0.9,1.3,2.4,1.9,4.5,1.9c1.9,0,3.3-0.6,4.4-1.9c1-1.3,1.5-3,1.5-5.1V14.1 c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4h4.1c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1V39c0,0.4-0.1,0.7-0.4,1 c-0.3,0.3-0.6,0.4-1,0.4H118c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1v-1.2c-0.8,0.8-1.8,1.6-3.1,2.2c-1.2,0.7-2.7,1-4.4,1 c-2.2,0-4-0.3-5.4-1c-1.4-0.7-2.6-1.6-3.4-2.7c-0.9-1.1-1.5-2.5-1.8-4c-0.4-1.5-0.5-3.1-0.5-4.8V14.1z"
        />
        <path
          id="Fill-12"
          className="st1"
          d="M136.4,39c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4h-4.1c-0.4,0-0.7-0.1-1-0.4 c-0.3-0.3-0.4-0.6-0.4-1V3.9c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4h4.1c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1V39z"
        />
        <path
          id="Fill-14"
          className="st1"
          d="M149.3,39c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4h-4.1c-0.4,0-0.7-0.1-1-0.4 c-0.3-0.3-0.4-0.6-0.4-1V3.9c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4h4.1c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1V39z"
        />
        <path
          id="Fill-16"
          className="st1"
          d="M187.5,24.7c-0.1,0.5-0.1,1.2-0.1,1.9c0,0.7,0,1.3,0.1,1.9c0.1,1.1,0.4,2,0.8,2.8 c0.4,0.8,0.9,1.4,1.5,1.9c0.6,0.5,1.2,0.8,2,1.1c0.7,0.2,1.5,0.3,2.2,0.3c0.9,0,1.6-0.1,2.3-0.2c0.6-0.1,1.1-0.3,1.6-0.6 c0.4-0.3,0.8-0.6,1.1-0.9c0.3-0.3,0.6-0.7,0.9-1.1c0.2-0.3,0.5-0.6,0.8-1c0.3-0.3,0.6-0.5,1-0.5h4.1c0.4,0,0.7,0.1,1,0.4 c0.3,0.3,0.4,0.6,0.4,1c0,0.7-0.3,1.7-0.8,2.7c-0.5,1.1-1.3,2.1-2.3,3.1c-1.1,1-2.4,1.8-4.1,2.5c-1.6,0.7-3.6,1-5.9,1 c-2,0-3.7-0.3-5.3-1c-1.6-0.7-2.9-1.6-4.1-2.7c-1.2-1.1-2.1-2.4-2.7-3.9c-0.7-1.5-1.1-3-1.2-4.7c-0.1-0.6-0.1-1.4-0.1-2.1 c0-0.8,0-1.5,0.1-2.2c0.1-1.6,0.5-3.2,1.2-4.7c0.7-1.5,1.6-2.8,2.7-3.9c1.1-1.1,2.5-2,4.1-2.7c1.6-0.7,3.4-1,5.3-1 c2.3,0,4.3,0.3,5.9,1c1.6,0.7,3,1.5,4.1,2.5c1.1,1,1.8,2,2.3,3.1c0.5,1.1,0.8,2,0.8,2.7c0,0.4-0.1,0.7-0.4,1 c-0.3,0.3-0.7,0.4-1,0.4h-4.1c-0.4,0-0.7-0.2-1-0.5c-0.3-0.3-0.5-0.6-0.8-1c-0.3-0.4-0.6-0.8-0.9-1.1c-0.3-0.3-0.7-0.6-1.1-0.9 c-0.4-0.3-0.9-0.5-1.6-0.6c-0.6-0.1-1.4-0.2-2.3-0.2c-0.8,0-1.5,0.1-2.2,0.3c-0.7,0.2-1.4,0.6-2,1.1c-0.6,0.5-1.1,1.1-1.5,1.9 C187.8,22.6,187.6,23.5,187.5,24.7"
        />
        <path
          id="Fill-18"
          className="st1"
          d="M224.4,19c-2.1,0-3.7,0.5-4.6,1.4c-0.9,0.9-1.4,2.5-1.4,4.6V39c0,0.4-0.1,0.7-0.4,1 c-0.3,0.3-0.6,0.4-1,0.4h-4.1c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1V14.1c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4h4.1 c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v1.2c0.9-0.9,1.9-1.5,3-2c1.1-0.5,2.4-0.7,4.2-0.7h0.4c0.4,0,0.7,0.1,1,0.4 c0.3,0.3,0.4,0.6,0.4,1v3.5c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4H224.4z"
        />
        <path
          id="Fill-20"
          className="st1"
          d="M239.2,39c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4h-4.1c-0.4,0-0.7-0.1-1-0.4 c-0.3-0.3-0.4-0.6-0.4-1V14.1c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4h4.1c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1V39z  M238,2.5c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v4.2c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4h-4.6c-0.4,0-0.7-0.1-1-0.4 c-0.3-0.3-0.4-0.6-0.4-1V3.9c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4H238z"
        />
        <path
          id="Fill-22"
          className="st1"
          d="M265.2,28c0.1-1,0.1-2,0-3c-0.2-2.3-0.9-4-2.1-5c-1.2-1-2.7-1.5-4.5-1.5c-1.8,0-3.2,0.5-4.4,1.6 c-1.2,1-1.9,2.5-2.1,4.4c-0.1,0.5-0.1,1.2-0.1,2s0,1.5,0.1,2c0.2,1.9,0.9,3.3,2.1,4.4c1.2,1.1,2.7,1.6,4.4,1.6 c1.8,0,3.2-0.5,4.5-1.5C264.3,32,265,30.3,265.2,28 M260.2,41c-1.1,0-2.1-0.1-3-0.3c-0.9-0.2-1.6-0.5-2.3-0.8s-1.2-0.7-1.7-1 c-0.5-0.4-0.8-0.7-1.1-1v11.4c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4h-4.1c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1V14.1 c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4h4.1c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v1.2c0.3-0.3,0.7-0.7,1.1-1 c0.5-0.4,1-0.7,1.7-1c0.7-0.3,1.4-0.6,2.3-0.8c0.9-0.2,1.9-0.3,3-0.3c1.8,0,3.4,0.3,4.9,1c1.5,0.7,2.7,1.6,3.7,2.8 c1,1.2,1.8,2.5,2.4,4c0.6,1.5,0.9,3.1,1,4.7c0,0.5,0.1,1.2,0.1,1.9s0,1.3-0.1,1.9c-0.1,1.6-0.4,3.2-1,4.7c-0.6,1.5-1.4,2.9-2.4,4 c-1,1.2-2.3,2.1-3.7,2.8C263.6,40.6,262,41,260.2,41"
        />
        <path
          id="Fill-24"
          className="st1"
          d="M277,12.5V3.9c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4h4.1c0.4,0,0.7,0.1,1,0.4 c0.3,0.3,0.4,0.6,0.4,1v8.7h6.9c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v3.5c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4h-6.9 v11.3c0,1.1,0.3,2,0.9,2.6c0.6,0.7,1.7,1,3.3,1h3.3c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1V39c0,0.4-0.1,0.7-0.4,1 c-0.3,0.3-0.6,0.4-1,0.4h-4c-3.4,0-6-0.8-7.8-2.4c-1.8-1.6-2.7-4.1-2.7-7.5V12.5z"
        />
        <path
          id="Fill-26"
          className="st1"
          d="M302.3,12.8c0.1,0,0.1,0,0.2,0.1s0.1,0.1,0.1,0.2v0.3c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.2,0.1 h-1.6v4.8c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.2,0.1h-0.3c-0.1,0-0.1,0-0.2-0.1c0,0-0.1-0.1-0.1-0.2v-4.8h-1.6 c-0.1,0-0.1,0-0.2-0.1c0,0-0.1-0.1-0.1-0.2V13c0-0.1,0-0.1,0.1-0.2s0.1-0.1,0.2-0.1H302.3z"
        />
        <path
          id="Fill-28"
          className="st1"
          d="M306,16.3l1.7-3.3c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1h0.3c0.1,0,0.1,0,0.2,0.1 c0,0,0.1,0.1,0.1,0.2v5.4c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.2,0.1h-0.3c-0.1,0-0.1,0-0.2-0.1c0,0-0.1-0.1-0.1-0.2v-3.9l-1.3,2.5 c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1h-0.3c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.2l-1.3-2.5v3.9 c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.2,0.1h-0.3c-0.1,0-0.1,0-0.2-0.1c0,0-0.1-0.1-0.1-0.2V13c0-0.1,0-0.1,0.1-0.2 c0,0,0.1-0.1,0.2-0.1h0.3c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2L306,16.3z"
        />
        <path
          id="Fill-31"
          className="st1"
          d="M170.2,32.5c0-0.4-0.1-0.7-0.4-1c-0.3-0.2-0.7-0.5-1.3-0.7c-0.6-0.2-1.5-0.4-2.5-0.6 c-1-0.2-2.4-0.5-4-0.9c-1.6-0.4-2.9-0.8-3.9-1.3c-1-0.5-1.9-1.2-2.5-1.9c-0.6-0.7-1.1-1.5-1.3-2.4c-0.3-0.9-0.4-1.9-0.4-3 c0-1.1,0.3-2.1,0.8-3.2c0.5-1,1.2-2,2.2-2.8c1-0.8,2.1-1.5,3.5-2c1.4-0.5,2.9-0.8,4.7-0.8c1.8,0,3.3,0.2,4.7,0.6 c1.4,0.4,2.6,0.9,3.5,1.6c1,0.6,1.7,1.4,2.3,2.2c0.5,0.8,0.8,1.7,0.9,2.5c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.7,0.4-1.1,0.4h-4.2 c-0.5,0-0.9-0.1-1.2-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.3-0.2-0.8-0.5-1.3-0.7c-0.5-0.2-1.3-0.3-2.4-0.3c-1.1,0-2,0.2-2.9,0.5 c-0.8,0.3-1.2,0.9-1.2,1.7c0,0.4,0.1,0.7,0.3,1c0.2,0.3,0.6,0.5,1.1,0.7c0.6,0.2,1.3,0.4,2.3,0.7c1,0.2,2.3,0.5,3.9,0.8 c3.2,0.7,5.5,1.7,6.8,3.2c1.3,1.4,2,3.2,2,5.4c0,1.1-0.3,2.1-0.8,3.1c-0.5,1-1.3,1.9-2.3,2.7c-1,0.8-2.3,1.4-3.8,1.9 c-1.5,0.5-3.2,0.7-5,0.7c-1.9,0-3.6-0.2-5-0.7c-1.5-0.4-2.7-1-3.7-1.7c-1-0.7-1.8-1.5-2.3-2.4c-0.5-0.9-0.8-1.8-0.9-2.6 c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.7-0.4,1.1-0.4h4.2c0.5,0,0.9,0.1,1.2,0.4c0.3,0.3,0.6,0.5,0.9,0.9c0.3,0.3,0.8,0.6,1.4,0.9 c0.6,0.3,1.5,0.4,2.7,0.4c0.5,0,1.1,0,1.7-0.1c0.6-0.1,1.1-0.2,1.6-0.3c0.5-0.2,0.9-0.4,1.2-0.6C170.1,33.2,170.2,32.9,170.2,32.5 "
        />
      </g>
    </g>
  </svg>
);
export default SvgFullscriptLogoGreenLeafPigeonText;
