import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import type { FloatingLabelPasswordProps } from "@aviary";
import { FloatingLabelPassword } from "@aviary";
import { FontAwesomeIcon } from "@shared/react-fontawesome/react-fontawesome";
import { validPasswordLength } from "@unauthenticated/shared/components/PasswordInput/PasswordValidation/PasswordValidation";
import { e2eAttributes } from "@unauthenticated/shared/e2eAttributes";
import { l } from "@unauthenticated/shared/locales/i18n";

import * as styles from "./PasswordInput.styles";

interface Props extends Omit<FloatingLabelPasswordProps, "id" | "label"> {
  errors?: ReactNode[];
  value: string;
  isDirty: boolean;
  isLoading: boolean;
  label?: string;
}

const PasswordInput = ({ isDirty, value, errors, disabled, isLoading, label, ...rest }: Props) => {
  const { t } = useTranslation();

  const isValid = validPasswordLength(value);

  const getErrors = () => {
    if (!isDirty) return null;
    if (isValid) return errors;

    return [...(errors || []), t(l.common.LengthValidation)];
  };

  const getDescriptiveText = () => {
    if (!isDirty || !isValid) return null;

    return (
      <>
        <FontAwesomeIcon icon={faCheck} size="sm" css={styles.icon} />
        {t(l.common.LengthConfirmation)}
      </>
    );
  };

  return (
    <FloatingLabelPassword
      descriptiveText={getDescriptiveText()}
      data-testid="password"
      id="password"
      name="password"
      data-e2e={e2eAttributes.practitionerSignup.password}
      label={label || t(l.common.Password)}
      value={value}
      errors={getErrors()}
      disabled={disabled || isLoading}
      {...rest}
    />
  );
};
export { PasswordInput };
