import { useTranslation } from "react-i18next";

import { useToast } from "@aviary";
import { l } from "@shared/locales/i18n";

const useCopyToClipboard = () => {
  const { t } = useTranslation();
  const { makeToast } = useToast();

  const copyToClipboard = async (
    content: string,
    { successMessage, errorMessage }: { successMessage?: string; errorMessage?: string }
  ) => {
    try {
      await navigator.clipboard.writeText(content);
      makeToast("success", successMessage ?? t(l.sharedCommon.CopiedToClipboard));
    } catch (error) {
      makeToast("error", errorMessage ?? t(l.sharedCommon.FailedToCopyText));
    }
  };

  return copyToClipboard;
};

export { useCopyToClipboard };
